import React from 'react'
import { CiPhone } from "react-icons/ci";
import { FaMapMarkerAlt } from "react-icons/fa";
import GetCall from './GetCall';
import { BsClock } from "react-icons/bs";



export default function GetCalLMapMsc() {
  return (
    <div className='get_call_map'>
        <div className='get_call_map_title'>
            <span>Синдикат 3D</span>
            </div>
        <div className='get_call_map_address'>
            <FaMapMarkerAlt className='get_call_map_svg'/>
            <span className='get_call_map_span'>Совхозная 29</span>
            </div>
        <div className="get_call_map_clock">
          <BsClock className='get_call_map_svg'/>
          <span className='get_call_map_span'>10:00-21:00</span>
        </div>
        <div className='get_call_map_phone'>
            <CiPhone className='get_call_map_svg'/>
            <span className='get_call_map_span'>+7 (969) 707-77-88</span>
            </div>
        <GetCall />
    </div>
  )
}
