import React from 'react';
import { YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import GetCalLMap from './GetCalLMap';
import GetCalLMapMsc from './GetCallMapMsc';
import placeMarkPhote from '../img/placemark.png';
import { useState } from 'react';

export default function YandexMap() {
    const [hidden, setHidden] = useState(false);

    return (
    <div>
        <div className='yandex_map_nav'>
            <div className='yandex_map_nav_item' onClick={()=>setHidden(false)}>Санткт-Петербург</div>
            <div className='yandex_map_nav_item' onClick={()=>setHidden(true)}>Москва</div>
        </div>
        {!hidden && (<div className='yandex_map'>
            <GetCalLMap />
            <YMaps>
                <Map defaultState={{ center: [59.874359, 30.310523], zoom: 11, controls: ["fullscreenControl"]}} width={`100%`} height={`500px`} modules={["control.ZoomControl", "control.FullscreenControl"]} >
                        <Placemark modules={["geoObject.addon.balloon"]} geometry={[59.874359, 30.310523]} options={{iconLayout: 'default#image', iconImageHref: placeMarkPhote, iconImageSize: [70, 70 ], iconImageOffset: [-30, -70]}} properties={{balloonContentBody:"Вход через магазин дикси, домофон 7"}}/>
                </Map>
            </YMaps>
        </div>)}
        {hidden &&(<div className='yandex_map'>
            <GetCalLMapMsc />
            <YMaps>
                <Map defaultState={{ center: [55.905598, 37.484667], zoom: 11, controls: ["fullscreenControl"]}} width={`100%`} height={`500px`} modules={["control.ZoomControl", "control.FullscreenControl"]} >
                        <Placemark modules={["geoObject.addon.balloon"]} geometry={[55.905598, 37.484667]} options={{iconLayout: 'default#image', iconImageHref: placeMarkPhote, iconImageSize: [70, 70 ], iconImageOffset: [-30, -70]}}/>
                </Map>
            </YMaps>
        </div>)}
    </div>
  )
}